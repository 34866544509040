import { Counter } from "./components/Counter";
import { FetchData } from "./components/FetchData";
import { Home } from "./components/Home";

import AllMeetsupPage from "./pages/AllMeetups";
import FavoritesPage from "./pages/Favorites";
import NewMeetupPage from "./pages/NewMeetup";

const AppRoutes = [
    {
        index: true,
        element: <Home />
    },
    {
        path: '/all-meetsup',
        element: <AllMeetsupPage />
    },
    {
        path: '/new-meetup',
        element: <NewMeetupPage />
    },
    {
        path: '/favorites',
        element: <FavoritesPage />
    }
];

export default AppRoutes;
