import { useNavigate} from 'react-router-dom';




import NewMeetupForm from '../components/meetups/NewMeetupForm';

function NewMeetupPage() {
    const navigate = useNavigate();
    

  function addMeetupHandler(meetupData) {
    fetch(
      'https://conferences-1de5f-default-rtdb.firebaseio.com/conferences.json',
      {
        method: 'POST',
        body: JSON.stringify(meetupData),
        headers: {
          'Content-Type': 'application/json',
        },
      }
    ).then(() => {
        navigate('/all-meetsup');
        
    });
  }

  return (
    <div>
      <h1>Add New Conference</h1>
      <NewMeetupForm onAddMeetup={addMeetupHandler} />
    </div>
  );
}

export default NewMeetupPage;
